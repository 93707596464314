import { Loader } from "components/shared/Loader/Loader";
import React, { Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import FeatureFlag from "components/feature_flag/FeatureFlag";

interface SuspenseLoaderProps {
  children: React.ReactNode;
  isFiltering?: boolean;
}

const SuspenseLoader = ({ children, isFiltering }: SuspenseLoaderProps) =>
  isFiltering ? (
    <Box width="100%" height="350px" display="flex" justifyContent="center" alignItems="center">
      <FeatureFlag name="dashboard-loader">
        <CircularProgress
          thickness={5}
          size={50}
          disableShrink
          sx={{
            color: "primary.main",
            "& .MuiCircularProgress-circle": {
              strokeLinecap: "round",
              strokeDasharray: "90, 150",
              strokeDashoffset: 0,
            },
          }}
        />
      </FeatureFlag>
    </Box>
  ) : (
    <Suspense
      fallback={
        <Loader
          isLoading={true}
          sx={{
            zIndex: 1050,
          }}
        />
      }
    >
      {children}
    </Suspense>
  );

export default SuspenseLoader;
