export const APP_VERSION = "0.0.1";

//  ENV_URL
export const ENV_UI_URL = process.env.REACT_APP_UI_URL;
export const ENV_API_URL = process.env.REACT_APP_API_URL;
export const ENV_UI_OPTIMIZATION = process.env.REACT_APP_PRICING_SIMULATION_URL;

export const ENV_OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || process.env.STORYBOOK_OKTA_CLIENT_ID;
export const ENV_OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER || process.env.STORYBOOK_OKTA_TOKEN_ISSUER;
export const FULLSTORY_ORGID = process.env.REACT_APP_FULLSTORY_ORGID;

export const GROWTHBOOK_CLIENT_ID = process.env.REACT_APP_GROWTHBOOK_CLIENT_ID;
export const GROWTHBOOK_HOST = process.env.REACT_APP_GROWTHBOOK_HOST;

export const CMS_CLIENT_ID = process.env.REACT_APP_CMS_CLIENT_ID;
export const CMS_PASSWORD = process.env.REACT_APP_CMS_PASSWORD;
export const CMS_USERNAME = process.env.REACT_APP_CMS_USERNAME;
export const CMS_BASE_URL = process.env.REACT_APP_CMS_BASE_URL || process.env.STORYBOOK_CMS_BASE_URL;
