// local dependencies.
import { emptySplitApi } from "../api/emptySplitApi";
import { DROPDOWN_DATA_URL } from "constants/apiEndpoints";
import { MainDropDownDataResponse } from "./types/mainDropdownDataTypes";

// Define service slice and endpoints.
const maindropdowndataapislice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMainDropDownData: builder.query<MainDropDownDataResponse, void>({
      query: () => DROPDOWN_DATA_URL,
      providesTags: [],
    }),
  }),
  overrideExisting: false,
});

// Define hooks.
const { useGetMainDropDownDataQuery } = maindropdowndataapislice;

// Export slice and hooks.
export { maindropdowndataapislice, useGetMainDropDownDataQuery };
