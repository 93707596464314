import { OktaAuth, AccessToken, TokenManagerInterface } from "@okta/okta-auth-js";
import config from "okta/config";

const getClient = () => {
  const authClient: OktaAuth | null = new OktaAuth(config.oidc);
  return authClient;
};

const getTokenManager = () => {
  const tokenManager: TokenManagerInterface | null = getClient().tokenManager;
  return tokenManager;
};

const getAccessToken = async () => {
  const token: AccessToken | null = (await getTokenManager().get("accessToken")) as AccessToken;
  return token;
};

const signOut = () => {
  const logout = getClient().signOut({
    revokeAccessToken: true,
    revokeRefreshToken: true,
    clearTokensBeforeRedirect: true,
  });
  return logout;
};

export { getClient, getTokenManager, getAccessToken, signOut };
