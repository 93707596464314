/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken } from "utils/okta";

async function apiFetch<T = any, R = AxiosResponse<T>>({
  responseType,
  method,
  url,
  data,
  params,
  signal,
}: AxiosRequestConfig): Promise<R> {
  const token = (await getAccessToken())?.accessToken;

  const config: AxiosRequestConfig = {
    method,
    url,
    signal,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      token: `${token}`,
    },
  };

  if (responseType) config.responseType = responseType;

  if (data) config.data = JSON.stringify(data);

  if (params && config.headers) {
    config.url = url + "?" + new URLSearchParams(params).toString();
  }

  //   if (params?.addHeadToken && config.headers) {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     const { addHeadToken, ...restParams } = params;
  //     config.headers = {};
  //     config.headers["Content-Type"] = "application/json";
  //     config.headers.token = `${token}`;
  //     config.url = url + "?" + new URLSearchParams(restParams).toString();
  //   }

  return axios.request(config);
}

export function apiPost<T = any>(data: AxiosRequestConfig) {
  return apiFetch<T>({ ...data, method: "POST" });
}

export function apiGet<T = any>(data: AxiosRequestConfig) {
  return apiFetch<T>({ ...data, method: "GET" });
}

export function apiPut<T = any>(data: AxiosRequestConfig) {
  return apiFetch<T>({ ...data, method: "PUT" });
}

export function apiPatch<T = any>(data: AxiosRequestConfig) {
  return apiFetch<T>({ ...data, method: "PATCH" });
}

export function apiDelete<T = any>(data: AxiosRequestConfig) {
  return apiFetch<T>({ ...data, method: "DELETE" });
}
