export enum MarketName {
  PMF = "PMF",
  FLUS = "FLUS",
  UK = "UK",
}

export type MarketIds = Record<MarketName, number>;

export interface MarketData {
  market: MarketName;
  id: number;
}
