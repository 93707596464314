import { createSlice } from "@reduxjs/toolkit";
import { MainDropDownData } from "./types/mainDropdownDataTypes";
import { maindropdowndataapislice } from "./mainDropdownDataSplitApi";
import { RootState } from "utils/store";

interface mainDropDownDataState {
  data: MainDropDownData[];
  loading: boolean;
  errorMsg: string | "";
}

/* =========================================
          initial State           
==========================================*/

const initialState: mainDropDownDataState = {
  data: [],
  loading: false,
  errorMsg: "",
};

const mainDropDownDataSlice = createSlice({
  name: "mainDropDownData",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(maindropdowndataapislice.endpoints.getMainDropDownData.matchFulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.mainDropDownData || [];
      state.errorMsg = "";
    });
  },
});

export const getMainDropDownData = (state: RootState) => state.mainDropDownData;
export const getHelpLink = (state: RootState) => state.mainDropDownData.data.find((item) => item.key === "help")?.value;

export const { reset } = mainDropDownDataSlice.actions;

export default mainDropDownDataSlice.reducer;
